<template>
 <b-container fluid>
    <b-row class="text-white bg-kpmg-pacific-blue py-4 mb-4">
      <b-col>
        <h1 class="kstyle">Sources</h1>
        <div>Regulators, other authorities and industry bodies that we track</div>
      </b-col>
    </b-row>
    <b-row>
  <b-container>
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading">
      <b-col>
        <b-card class="mb-0" style="z-index: 1;">
          <b-button class="mr-2" variant="outline-primary" v-on:click="getXls">download</b-button>
          <b-button v-if="permissions.add" variant="outline-primary" :to="{ name: 'RegulatorAdd' }">add source</b-button>
        </b-card>
        <vue-datamaps :data="countriesData" @showcountry="showCountry" style="margin-top: -100px; margin-bottom: -150px; z-index: 0;" />
        <b-form inline class="mt-0 mb-5">
          <b-button style="z-index: 9999;" class="mr-2" variant="outline-primary" v-on:click="showCountry('EUR')">EU</b-button>
          <b-button style="z-index: 9999;" class="mr-2" variant="outline-primary" v-on:click="showCountry('INT')">International</b-button>
        </b-form>
        <div>
          <strong>{{ this.rows.length }} sources from {{this.countryCount}} countries</strong>
        </div>
        <div>
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search" />
              <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
          </b-input-group>
        </div>
        <b-table id="regtable"
          striped
          hover
          :items="rows"
          :fields="fields"
          :filter="filter"
          @row-clicked="showRegulator"
          style="cursor: pointer;" ></b-table>
      </b-col>
    </b-row>
  </b-container>
  </b-row>
</b-container>
</template>

<script>
import _ from 'lodash'
import countries from '../libs/countries'
import zipcelx from 'zipcelx'
import VueDatamaps from '@/components/datamaps/Datamaps.vue'

import ac from '../libs/accesscontrol'

export default {
  name: 'Regulators',
  components: {
    VueDatamaps
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: Regulators"
    this.$stat.log({ page: 'regulators', action: 'open regulators' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('regulator').granted
    this.loadRisks()
  },
  data () {
    return {
      countriesData: {},
      countryCount: 0,
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'description',
          sortable: true
        },
        {
          key: 'country',
          sortable: true
        }
      ],
      filter: '',
      permissions: {
        add: false
      },
      buttonvariant: 'primary',
      loading: true,
      rows: ''
    }
  },
  methods: {
    getXls: function () {
      let data = []
      const headings = [
        { value: 'Code', type: 'string' },
        { value: 'Name', type: 'string' },
        { value: 'Country', type: 'string' }
      ]
      data.push(headings)
      for (let i = 0, len = this.rows.length; i < len; i++) {
        let row = [
          { value: this.rows[i].name, type: 'string' },
          { value: this.rows[i].description, type: 'string' },
          { value: this.rows[i].country, type: 'string' }
        ]
        data.push(row)
      }
      const config = { filename: 'regulators', sheet: { data: data } }
      zipcelx(config)
    },
    loadRisks: async function () {
      this.$logger.debug('loading started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/regulators/startpage'
        let response = await this.$Amplify.API.get(apiName, path)
        this.rows = _.sortBy(response, 'name')
        this.loading = false
        const countriesData = {}
        let cs = this.rows.map(x => x.country)
        cs = _.uniq(cs)
        this.countryCount = cs.length
        cs.forEach(x => {
          const country = _.find(countries, ['country', x])
          const value = { fillKey: 'exists' }
          Object.defineProperty(countriesData, country.alpha3, { value: value, writable: true })
        })
        this.countriesData = countriesData
      } catch (e) {
        this.$logger.wrn('load ERROR: ', e)
      }
    },
    showCountry (id) {
      const country = _.find(countries, ['alpha3', id])
      this.filter = country.country
    },
    showRegulator: function (regulator, index, event) {
      this.$router.push({ name: 'Regulator', params: { code: regulator.name } })
    }
  }
}
</script>

<style>
</style>
